<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron subsistemas afectados"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'three-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="orden_trabajo_subsis.idorden_trabajo_subsis"
    >
      <template
        #listItem="slotProps"
      >
        <orden-trabajo-subsis-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          :readonly="
            slotProps.item.orden_trabajo.estado_idclasificacion !== CLASIFICACION_ESTADO_ORDEN_TRABAJO.clasificacion.pendiente ||
              slotProps.item.cantMaterialAfectado > 0 ||
              slotProps.item.cantMaterialInstalar > 0 ||
              slotProps.item.cantChecklistSubsis > 0
          "
          @click-delete="clickDelete"
        />
      </template>
    </b10-list>
    <b10-fab-button
      v-if="estadoOTPendiente"
      @click="clickAdd"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './OrdenTrabajoSubsisListData'
import OrdenTrabajoSubsisListItem from './components/OrdenTrabajoSubsisListItem'
import { CLASIFICACION_ESTADO_ORDEN_TRABAJO } from '@/utils/consts'
import { get } from 'vuex-pathify'

const pageStoreName = 'pagesOfflineOrdenTrabajoSubsisList'

export default {
  components: {
    OrdenTrabajoSubsisListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      CLASIFICACION_ESTADO_ORDEN_TRABAJO,
      ordenTrabajo: {},
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    estadoOTPendiente () {
      return (
        this.ordenTrabajo &&
        this.ordenTrabajo.orden_trabajo &&
        this.ordenTrabajo.orden_trabajo.estado_idclasificacion === CLASIFICACION_ESTADO_ORDEN_TRABAJO.clasificacion.pendiente
      )
    },
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Subsistemas afectados'
      this.ordenTrabajo = await this.$offline.ordenTrabajo.row(this.routeParams.idorden_trabajo)
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectOrdenTrabajoSubsis(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idorden_trabajo
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectOrdenTrabajoSubsisRows(this, dirty.modified), dirty, 'orden_trabajo_subsis.idorden_trabajo_subsis'
        )
      } finally {
        this.loadingData = false
      }
    },
    clickAdd () {
      // sin remember porque agrega un registro
      this.$appRouter.push({
        name: 'offline__orden-trabajo-subsis-add',
        params: {
          idorden_trabajo: this.routeParams.idorden_trabajo,
          idparte_trabajo: this.routeParams.idparte_trabajo,
        },
      })
    },
    async clickDelete (data) {
      const res = await this.$alert.showConfirm(
        `¿Deseas eliminar el subsistema "${data.item.subsis.descripcion}" de la orden de trabajo?`
      )
      if (res) {
        this.$loading.showManual('Eliminando...')
        try {
          await Data.deleteOrdenTrabajoSubsis(this, data.item.orden_trabajo_subsis.idorden_trabajo_subsis)
          await this.dispatchStore('deleteItem', data.index)
        } finally {
          this.$loading.hide()
        }
      }
    },
  }
}
</script>
